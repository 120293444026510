export const translateLabel = {
    'fr': {
        'title': "Les Laines Biscotte",
        'generator': "Générateur de patrons",
        'pattern': "Patron",
        'itemSize': "Taille désirée",
        'mesh': "Nombre de mailles par 10 cm (3.9'')",
        'ranks': "Nombre de rangs par 10 cm (3.9'')",
        'measureUnit': "Unité de mesure",
        'sewingMethod': "Méthode préférée",
        'sewingType': "Type de talon",
        'fitment': "Aisance",
        'submit-btn':"Générer le patron",
        'itemSizeError': "Taille désirée est oubligatoire",
        'meshError': "Nombre de mailles est oubligatoire",
        'ranksError': "Nombre de rangs est oubligatoire",
        'measureUnitError': "Unité de mesure est oubligatoire",
        'sewingMethodrror': "Méthode préférée est oubligatoire",
        'sewingTypeError': "Type de talonest oubligatoire",
        'fitmentError': "fitment est oubligatoire",
        'require': "requis ",
        'submit': "Soumettre",

    },
    'en': {
        'title': "Biscotte Yarns",
        'generator': "Pattern Generator",
        'pattern': "Pattern",
        'itemSize': "Desired Size",
        'mesh': "Number of stitches per 10 cm (3.9'')",
        'ranks': "Number of rows per 10 cm (3.9'')",
        'measureUnit': "Measure Unit",
        'sewingMethod': "Preferred Method",
        'sewingType': "Heel Type",
        'fitment': "Ease",
        'submit-btn':"Generate the Pattern",
        'itemSizeError': "Desired Size is require",
        'meshError': "Number of stitches is require",
        'ranksError': "Number of rows is require",
        'measureUnitError': "Measure Unit is require",
        'sewingMethodError': "Preferred Method is require",
        'sewingTypeError': "Heel Type is require",
        'fitmentError': "fitment is require",
        'require': "required",
        'submit': "Submit"

    }
};