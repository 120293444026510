export const extractData =(data) => {
    let arr = [];
    const d = data.data;
    const type = data.type;
    Object.keys(d).forEach(function (key) {
        arr.push({label:d[key], key: key});
    });

    return arr.map((t) => {
        return {value: {key:t.key, type:type}, label: t.label}
    });
}

export const extractingData =(data) => {
    let arr = [];
    Object.keys(data).forEach(function (key) {
        arr.push({name: data[key].name, key: key});
    });

    return arr.map((pattern) => {
        return {value: pattern.key, label: pattern.name}

    });
}


