import React, {Fragment} from 'react';
import Pattern from './component/pattern/Pattern';
import { useDataApi } from './provider/useDataApi';
import Spinner from 'reactstrap/lib/Spinner';
import './App.css'


export default function App() {
    const ENTRYPOINT = `${process.env.REACT_APP_API_ENTRYPOINT}/getData.php`;
    const { data, isLoading } = useDataApi(ENTRYPOINT, {patterns: []});
    const isFetching = !(data.patterns !== undefined && data.patterns.length === 0);

    if (isLoading) {
        return (
          <Fragment>
                <div className='row'>
                    <div className='col-sm-6'>
                        <Spinner animation="border" variant="warning"/>
                    </div>
                </div>
          </Fragment>
        );
    }
    return (<Pattern data={data} isFetching={isFetching}/>);
};


