import React, {Component, Fragment} from 'react';
import BaseSelect from 'react-select';
import Spinner from 'reactstrap/lib/Spinner';
import { getLanguage, setDefaultLanguage, setLanguage, setTranslations, translate } from 'react-switch-lang';
import PropTypes from 'prop-types';
import FixRequiredSelect from '../FixRequiredSelect';
import { extractingData, extractData } from '../../utils/extractData';
import { translateLabel } from '../../utils/translation';
import { Button, ButtonToolbar } from 'reactstrap';
import './Pattern.css'
import axios from 'axios';


const Select = props => (
    <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);
const isFirefox = typeof InstallTrigger !== 'undefined';
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isSafari = /constructor/i.test(window["HTMLElement"]) || (function(p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window["safari"] !== 'undefined' && window["safari"].pushNotification))

class Pattern extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            itemSizeSelected: [],
            isPatternSelected: false,
            isFetching: props.isFetching,
            pattern: null,
            key: null,
            pdf: false,
            pdfUrl: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.onChangePattern = this.onChangePattern.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showFile = this.showFile.bind(this);
        const locale = window.location.search;
        if( locale !== undefined){
            const language = locale.replace(/\?locale=/g, '');
            if(language !== undefined && (language === 'fr' || language === 'en')) {
                setLanguage(language);
            }
        } else {
            setDefaultLanguage('en');
        }
        setTranslations(translateLabel);
    }

    showFile = blob => {
        var newBlob = new Blob([blob], {type: "application/pdf"})

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = "biscotte-pattern.pdf";
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    };

    handleChange = itemSizeSelected => {
        this.state.itemSizeSelected[itemSizeSelected.value.type] = {value:itemSizeSelected.value.key};
        this.setState(this.state.itemSizeSelected);
    };

    handleSetLanguage = () => {
        const result = getLanguage() === 'fr' ? 'en' : 'fr';
        setLanguage(result);
    };

    onChangePattern = selectedOption => {
        const st = {
            pattern: selectedOption.value,
            isPatternSelected: true,
            itemSizeSelected: [],
            key: Math.random()
        }
        this.setState(st);
    }

    handleSubmit(event) {
        const ENTRYPOINT = `${process.env.REACT_APP_API_ENTRYPOINT}/getPDF.php?`;
        const { itemSizeSelected } = this.state;
        let arrParams = [];

        arrParams.push('pattern=' + this.state.pattern);
        arrParams.push('&locale=' + getLanguage());

        for(const type in itemSizeSelected){
            arrParams.push('&' + type + '=' + itemSizeSelected[type].value);
        }
        const path = ENTRYPOINT + arrParams.join('');

        axios({
            url: path ,
            headers: {
                'Accept': 'application/pdf',
                'Content-Type': 'application/pdf',
            },
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const file = new Blob([response.data], {type: "application/pdf;charset=utf-8"});
            console.log(response);
            this.showFile(response.data);
        });
        event.preventDefault();
    }


    createSelect = (t, data) => {
        let select = []

        for(const val in data){
            data[val].type = val;
            const option = extractData(data[val]);
            const label = data[val].name;

            select.push(<div key={label} className="form-group">
                {label === 'fitment' ? t('fitment'): label} <i className="text-danger">{t('require')}</i>
                <Select key={this.state.key} options={option} onChange={this.handleChange} required />
            </div>)

        }
        return select;
    }

    render() {
        if (!this.state.isFetching) {
            return (
                <Fragment>
                    <div className={'row'}>
                        <div className={'col-sm-6'}>
                            <Spinner animation="border" variant="warning"/>
                        </div>
                    </div>
                </Fragment>
            );
        }

        const { data, isPatternSelected, pattern, pdf} = this.state;
        const {t} = this.props;
        const lan = getLanguage() === 'en' ? 'FR': 'EN';
        const d = data[getLanguage()].data;
        const pat = extractingData(d);

        return (
            <div className="container">
                <ButtonToolbar>
                    <Button className="toolbarBnt btn btn-primary btn-sm" onClick={this.handleSetLanguage}>
                        {lan}
                    </Button>
                </ButtonToolbar>
                <h1 className={'title'}>
                    {t('title')}
                </h1>
                <hr />
                <div className="card mb-3">
                    <h3 className="card-header">{t('generator')}</h3>
                    <div className="card-body">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                {t('pattern')}
                                <Select options={pat} onChange={this.onChangePattern}/>
                            </div>
                            { isPatternSelected ? <div>
                                    {this.createSelect(t,d[pattern].data)}
                                    <button className="btn btn-primary">{t('submit')}</button>
                                     </div>
                                :''
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


Pattern.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Pattern)
